import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  VerifyEmailLandingPageComponent,
  AuthenticationOverviewComponent,
  TipComponent,
  TipsModule,
  ContentItemListingWithSearchAndFilters,
  TopicLearnerLandingPageComponent,
  AuthGuard} from '@frontend/common';

import { HomeComponent } from './home/home.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path: 'login', component: AuthenticationOverviewComponent,  data: { appKey: 'culturettt'/*used in the component until we get environment variables working*/} },
  { path: 'register', component: AuthenticationOverviewComponent,  data: { domain: 'culturettt.com', appKey: 'culturettt'/*used... as above'*/} },
  { path: 'forgot-password', component: AuthenticationOverviewComponent,  data: { domain: 'culturettt.com', appKey: 'culturettt'/*used... as above'*/}  },
  { path: 'email/verify/:user_id/:hash', component: VerifyEmailLandingPageComponent,  data: { appKey: 'culturettt'/*used... as above'*/}  },
  { path: 'password/reset/:hash/:email', component: AuthenticationOverviewComponent,  data: { appKey: 'culturettt'/*used... as above'*/, label: 'Reset password', translationKey: 'authentication.new_password'}  },

 { path: 'hubs',canActivate:[AuthGuard], loadChildren: () => import('@frontend/common').then(m => m.HubsModule), data: { label: 'Hubs', translationKey: 'navigation.hubs', labelSource: null,consent:['dp','ll','cl']} },
 { 
  path: 'contributions', canActivate:[AuthGuard],// Temporarily here. Will be moved to the Interculturalist website
  loadChildren: () => import('@frontend/common').then(m => m.ContributionsModule),
  data: { label: 'Contributions', labelSource: null, consent:['ct']},
},

 { path: 'account', canActivate:[AuthGuard], loadChildren: () => import('@frontend/common').then(m => m.UserAccountModule), data: { label: 'Account', translationKey: 'navigation.account', labelSource: null} },
  
 { path: 'connections', canActivate:[AuthGuard], loadChildren: () => import('@frontend/common').then(m => m.UserConnectionsModule),
     data: { label: 'Connections', translationKey: 'navigation.connections', labelSource: null,consent:['dj']},
    },

  { path: 'profile',canActivate:[AuthGuard], loadChildren: () => import('@frontend/common').then(m => m.ProfileUserModule), data: { label: 'User profile', translationKey: 'navigation.profile', labelSource: null, consent:['so']} },

  { path: 'design',canActivate:[AuthGuard], loadChildren: () => import('@frontend/common/design').then(m => m.DesignModule), data: { label: 'Design', translationKey: 'navigation.design', labelSource: null} },

  { path: 'designer',canActivate:[AuthGuard], loadChildren: () => import('./design/design.module').then(m => m.DesignModule), data: { label: 'Design', translationKey: 'navigation.design', labelSource: null} }, // CKEditor testing
  { 
    path: 'accreditations',
    data: { label: 'Accreditations', translationKey: 'navigation.accreditations', consent:['so'] },
    loadChildren: () => import('@frontend/common/accreditations').then(m => m.AccreditationsModule),
  },
  {
    path: ':topic',
    data: { label: 'Topic home', translationKey: null, labelSource: 'titleService'},
    children: [
      {
        path: '',
        component: TopicLearnerLandingPageComponent,
      },
      { 
        path: 'accreditations',
        data: { label: 'Accreditations', translationKey: 'navigation.accreditations' },
        loadChildren: () => import('@frontend/common/accreditations').then(m => m.AccreditationsModule),
      },
      { 
        path: 'courses',
        data: { label: 'Courses', translationKey: 'navigation.courses' },
        loadChildren: () => import('@frontend/common').then(m => m.CoursesModule),
      },
      {
        path: 'tips',
        data: { label: 'Tips', translationKey: 'navigation.tips', className: 'tips', },
        children: [
          {
            path: '',
            component: ContentItemListingWithSearchAndFilters,
            data: {
              contentClassName: 'tips',
              relatedModelClassName: 'topic', // This should also be the name of the urlFragment for the breadcrumb and route param
              tagTypeFilters:['tool_feature'],
              excludeTagsWithEmptyResults: true,
              sortCriteria: [ // must conform to SortCriterion[] type
                {sort_by:'published_at',sort_order:null,default_sort_order:'desc',translationKeyAsc:'common.first',translationKeyDesc:'common.latest'}, // this first SortCriterion will be the default
                {sort_by:'title',sort_order:null,default_sort_order:'asc',translationKeyAsc:'content_management.title',translationKeyDesc:'content_management.title'},
              ],
              cultureFilters : null, // [{category:'geographic', type:'national', variableName: 'nationalCultures'}]
              topicFilters : null, // [{category:'interculturalist', type:null, variableName: 'interCulturalistTopics'}]
            },
          },
          {
            path: ':tip',
            component: TipComponent,
            data: { label: 'Tip', translationKey: null, labelSource: 'titleService' },
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '' },
  {
    path: 'coming-soon',
    loadChildren: () =>
      import('@frontend/shared').then((m) => m.ComingSoonModule),
  },
];

@NgModule({
  imports: [
    TipsModule,
    RouterModule.forRoot(appRoutes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
