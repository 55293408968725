import { Component, OnInit, signal } from '@angular/core';
import { AuthService, LogoutService, NotificationService, PaginatedNotifications } from '@frontend/common';
import { ActivityLogService } from '@frontend/core';

import { User } from '@frontend/common';
import { MainNavigationLink } from '@frontend/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ttt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Culture TTT';

  logoutObs;
  user: User = null;
  userSubscription : Subscription ;
  notificationSubscription : Subscription ;
  isAuthenticated: boolean = false;
  loading = false;
  error = null;
  sidenavMode : string = 'slim';
  mainNavigationLinks: MainNavigationLink[];
  brandLogoBanner: string; // temporary solution TODO move this logic elsewhere

  constructor(
    private authService: AuthService,
    private activityLogService: ActivityLogService,
    private logoutService : LogoutService,
    private notificationService : NotificationService,
  ) {
    this.mainNavigationLinks = [
      // {labelText: null, labelLanguageKey : 'platform.culturequestion', routerLinkValue: ['/culturequestion'], condition: ''},
      // {labelText: null, labelLanguageKey : 'platform.ciprofile', routerLinkValue: ['/ciprofile'], condition: ''},
      // {labelText: null, labelLanguageKey : 'platform.interculturalist', routerLinkValue: ['/interculturalist'], condition: ''},
      {labelText: null, labelLanguageKey : 'content.contributions', routerLinkValue: ['/contributions'], condition: ''}, // Temporarily here. Will be moved to the Interculturalist website
      // {labelText: null, labelLanguageKey : 'navigation.hubs', routerLinkValue: ['/interculturalist-hubs'], condition: ''},
      {labelText: null, labelLanguageKey : 'platform.cco', routerLinkValue: ['/cultureconnector'], condition: ''},
    ];
  }

  logout() {
    this.loading = true;
    this.logoutObs = this.logoutService.logout();
    this.logoutObs.subscribe(
      (response) => {
        this.loading = false;
      },
      (error) => {
        this.error = error;
        this.loading = false;
      }
    );
  }

  get notifications(){
    return this.notificationService.topThreeUnreadNotifications;
  };
  
  getUser() {
    this.loading = true;
    this.error = null;

    let getUserSub = this.authService.getUser(null).subscribe(
      () => {
        this.loading = false;
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.loading = false;
      }
    );
  }
  onNotificationSelected(notification : Notification){
    alert('Notification ' + JSON.stringify(notification));
  }
  changeSidenavMode (newMode : string) {
    newMode = newMode ? newMode : this.sidenavMode === 'wide' ? 'slim' : 'wide';
    this.sidenavMode = newMode;
  };
  ngOnInit() {
    this.userSubscription = this.authService.user.subscribe(
      (user) => {
        this.user = user;
        this.isAuthenticated = !!user;
        this.activityLogService.logDefaultSiteVisit();
        if(this.isAuthenticated){
          this.notificationSubscription = this.notificationService.getNotifications(1).subscribe();
        }
      },
      (error) => {
        this.activityLogService.logDefaultSiteVisit();
      }
    );
    if (this.authService.token) {
      this.getUser();
    }
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.logoutObs.unsubscribe();
  }
}
